<template>
  <div class="species">
    <div class="content">
      <div class="button-wrap" v-if="!mixinSortModeFlg">
        <router-link :to="'species/new'">
          <base-button-register :text="'種別登録'" v-if="lookOnlyFlg === 0" />
        </router-link>
        <base-sort-button
          class="button"
          v-if="lookOnlyFlg === 0"
          @click="setSortMode"
        />
      </div>
      <div class="button-wrap" v-else>
        <base-button-small-white class="button" @click="cancelSortMode"
          >キャンセル</base-button-small-white
        >
        <base-button-small-orange
          class="button"
          v-if="lookOnlyFlg === 0"
          @click="updateOrder"
          :disabled="waitFlg"
          >並び順登録</base-button-small-orange
        >
      </div>
      <div class="list-wrap">
        <div class="heading">
          種別一覧<span v-if="mixinSortModeFlg">{{
            mixinSortModeMessage
          }}</span>
        </div>
        <list-table
          class="species-list"
          :headerData="headers"
          :propertyData="properties"
          :bodyData="displaySpecies"
          :sortModeFlg="mixinSortModeFlg"
          @click="openShowPage"
          @order="changeOrder"
        />
      </div>
    </div>
    <announce-popup
      v-if="mixinSortPopup.showFlg"
      :type="mixinSortPopup.type"
      :title="mixinSortPopup.title"
      :buttons="mixinSortPopup.buttons"
      @close="mixinCloseSortPopup"
      >{{ mixinSortPopup.message }}</announce-popup
    >
    <unsaved-leave-popup />
  </div>
</template>

<script>
import BaseButtonRegister from '@/components/parts/atoms/BaseButtonRegister'
import BaseSortButton from '@/components/parts/atoms/BaseSortButton'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseButtonSmallOrange from '@/components/parts/atoms/BaseButtonSmallOrange'
import ListTable from '@/components/parts/organisms/ListTable.vue'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import ApiOrder from '@/components/mixins/ApiOrder'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import { mapGetters } from 'vuex'

export default {
  name: 'Species',

  components: {
    BaseButtonRegister,
    BaseSortButton,
    BaseButtonSmallWhite,
    BaseButtonSmallOrange,
    ListTable,
    AnnouncePopup,
    UnsavedLeavePopup
  },

  mixins: [ApiOrder, CheckInputDifference],

  data() {
    return {
      headers: ['標準', '種別名'],
      properties: ['order', 'name'],
      waitFlg: false
    }
  },

  computed: {
    ...mapGetters({
      species: 'species/getData',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    displaySpecies() {
      return this.mixinSortModeFlg ? this.mixinInputData : this.species
    }
  },

  watch: {
    mixinSortModeFlg: function() {
      this.setData()
    }
  },

  created() {
    this.setData()
  },

  methods: {
    setData() {
      this.mixinInputData = this.species
      this.mixinSetInitialData()
    },
    openShowPage(id) {
      this.$router.push({ path: `species/${id}` })
    },
    setSortMode() {
      this.mixinSortModeFlg = true
    },
    cancelSortMode() {
      this.mixinSortModeFlg = false
    },
    changeOrder(data) {
      this.mixinInputData = data.map(v1 =>
        this.mixinInputData.find(v2 => v2.id === v1.id)
      )
    },
    async updateOrder() {
      this.waitFlg = true
      await this.mixinUpdateOrder('species', this.mixinInputData)
      if (this.mixinSortSucceeded) this.mixinSetInitialData()
      this.waitFlg = false
    }
  }
}
</script>

<style lang="scss" scoped>
.species {
  width: 100%;
  min-width: 614px;
  text-align: left;
  > .content {
    margin-top: 20px;
    margin-bottom: 24px;
    > .button-wrap {
      display: flex;
      justify-content: flex-end;
      > .button {
        margin-left: 20px;
      }
    }
    > .list-wrap {
      margin-top: 16px;
      ::v-deep .list-table .table-body {
        max-height: 560px;
      }
      .heading {
        margin-bottom: 16px;
        font-size: 15px;
        font-weight: bold;
      }
    }
  }
}
</style>
