<template>
  <div class="clinic-information">
    <validation-observer
      class="validation-observer"
      ref="form"
      v-slot="{ invalid }"
    >
      <div class="section input-area">
        <base-image-uploader
          class="img"
          :image="clinic.image"
          @input="inputImage"
          @delete="deleteImage"
          @uploadingImg="setUploadingImg"
        />
        <clinic-information-input-area
          :adminFlg="adminFlg"
          :clinic="clinic"
          :apiResultPostalCode="mixinApiResultPostalCode"
          @auto-input-address="mixinInputAddress('clinic')"
          @input-postal-code="inputPostalCode"
        />
      </div>
      <div class="section button-area">
        <base-button-medium-orange
          class="button"
          v-if="lookOnlyFlg === 0 && (adminFlg === 1 || supportFlg === 1)"
          :disabled="invalid || waitFlg || uploadingImg"
          @click="updateData"
          >登録</base-button-medium-orange
        >
      </div>
      <announce-popup
        v-if="alertFlg"
        :title="title"
        :buttons="buttons"
        :type="type"
        @close="closeAlert"
        >{{ popupMessage }}</announce-popup
      >
      <unsaved-leave-popup />
    </validation-observer>
  </div>
</template>

<script>
import ClinicInformationInputArea from '@/components/parts/organisms/ClinicInformationInputArea'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import AutoInputPrefecture from '@/components/mixins/AutoInputPrefecture'
import { ValidationObserver } from 'vee-validate'
import { mapGetters } from 'vuex'
import BaseImageUploader from '@/components/parts/atoms/BaseImageUploader'

export default {
  name: 'ClinicInformation',

  components: {
    ClinicInformationInputArea,
    BaseButtonMediumOrange,
    AnnouncePopup,
    UnsavedLeavePopup,
    ValidationObserver,
    BaseImageUploader
  },

  mixins: [CheckInputDifference, AutoInputPrefecture],

  data() {
    return {
      alertFlg: false,
      waitFlg: false,
      title: '',
      buttons: [],
      type: '',
      popupMessage: '',
      clinic: {},
      uploadingImg: false
    }
  },

  computed: {
    ...mapGetters({
      getMasterDatum: 'master/getDataById',
      lookOnlyFlg: 'auth/lookOnlyFlg',
      adminFlg: 'auth/adminFlg',
      supportFlg: 'auth/supportFlg'
    })
  },

  watch: {
    'clinic.tel': async function(val) {
      if (val.indexOf('-') !== -1) {
        this.$set(this.clinic, 'tel', await val.replace('-', ''))
      }
    },
    'clinic.postalCode': async function(val) {
      if (val.indexOf('-') !== -1) {
        this.$set(this.clinic, 'postalCode', await val.replace('-', ''))
      }
    }
  },

  created() {
    this.setData()
  },

  methods: {
    setData() {
      this.clinic = { ...this.$store.getters['clinic/getData'] }
      this.mixinInputData = this.clinic
      this.mixinSetInitialData()
    },
    inputImage(image) {
      this.clinic.image = image
    },
    deleteImage() {
      this.clinic.image = null
    },
    inputPostalCode(val) {
      if (
        !this.mixinApiResultPostalCode?.existingPostalCodeFlg &&
        val.length !== 7
      ) {
        this.mixinApiResultPostalCode = null
      }
      this.clinic.postalCode = val
    },
    async updateData() {
      this.waitFlg = true
      const res = await this.$store.dispatch('clinic/update', this.clinic)
      if (res === true) {
        this.title = '完了'
        this.buttons = ['閉じる']
        this.type = 'success'
        this.popupMessage = '編集しました'
        this.setData()
      } else {
        this.title = '失敗'
        this.buttons = ['閉じる']
        this.type = 'failure'
        this.popupMessage = '編集に失敗しました'
      }
      this.waitFlg = false
      this.alertFlg = true
    },
    closeAlert() {
      this.alertFlg = false
    },
    setUploadingImg(uploadingImgState) {
      this.uploadingImg = uploadingImgState
    }
  }
}
</script>

<style lang="scss" scoped>
.clinic-information {
  text-align: left;
  margin-top: 38px;
  > .validation-observer {
    > .section {
      margin-top: 25px;
      display: flex;
      > .img {
        margin-right: 30px;
      }
      &.button-area {
        display: flex;
        justify-content: center;
        margin: 60px 0;
      }
    }
  }
}
</style>
