<template>
  <div class="flg-text-input-form">
    <div class="label">
      <div class="text">{{ label }}</div>
      <hint-balloon v-if="note" :note="note" />
      <div class="required" v-if="requiredFlg">必須</div>
    </div>
    <div class="value flg">
      <div
        class="radio-button"
        v-for="datum in radioButtonData"
        :key="datum.id"
      >
        <base-radio-button
          :value="flgValue"
          :option="datum"
          :styles="radioButtonStyle"
          :disabled="flgDisabled"
          @input="inputFlg"
        />
      </div>
    </div>
    <div class="value text">
      <validation-provider
        class="validate"
        :rules="{
          ...validationRules,
          requiredRule: requiredFlg,
          notSurrogatePair: true
        }"
        v-slot="{ errors }"
      >
        <base-text-box
          :value="textValue"
          :maxlength="maxlength"
          :placeholder="placeholder"
          :disabled="textDisabled"
          :disabledColorWhite="textDisabledColorWhite"
          :styles="textStyle"
          :fullWidthNumbersToHalfWidthNumbers="
            fullWidthNumbersToHalfWidthNumbers
          "
          @input="inputText"
        />
        <div class="error">
          <div v-for="(error, index) in errors" :key="index">{{ error }}</div>
        </div>
      </validation-provider>
    </div>
  </div>
</template>

<script>
import BaseRadioButton from '@/components/parts/atoms/BaseRadioButton'
import HintBalloon from '@/components/parts/atoms/HintBalloon'
import BaseTextBox from '@/components/parts/atoms/BaseTextBox'
import { ValidationProvider } from 'vee-validate'
import '@/utils/validation_rules'

export default {
  name: 'FlgTextInputForm',

  components: {
    BaseRadioButton,
    HintBalloon,
    BaseTextBox,
    ValidationProvider
  },

  props: {
    requiredFlg: { type: Boolean, default: false },
    label: { type: String, default: '' },
    note: { type: String, default: '' },
    balloonStyles: { type: Object },
    flgDisabled: { type: Boolean, default: false },
    radioButtonData: { type: Array },
    flgValue: { type: [String, Number] },
    validationRules: { type: Object },
    maxlength: { type: Number, default: 255 },
    placeholder: { type: String, default: '' },
    textDisabled: { type: Boolean, default: false },
    textDisabledColorWhite: { type: Boolean, default: false },
    textStyle: { type: Object },
    textValue: { type: [String, Number], default: '' },
    fullWidthNumbersToHalfWidthNumbers: { type: Boolean, default: false }
  },

  data() {
    return {
      radioButtonStyle: {
        paddingLeft: '5px',
        marginRight: '10px'
      }
    }
  },

  methods: {
    inputFlg(flg) {
      this.$emit('input-flg', flg)
    },
    inputText(text) {
      this.$emit('input-text', text)
    }
  }
}
</script>

<style lang="scss" scoped>
.flg-text-input-form {
  display: flex;
  align-items: center;
  font-size: 15px;
  > .label {
    display: flex;
    align-items: center;
    width: 240px;
    > .required {
      margin-left: 10px;
      font-size: 13px;
      color: #{$tomato};
    }
  }
  > .value.flg {
    display: flex;
    margin-right: 15px;
    > .radio-button {
      ::v-deep .base-radio-button label {
        padding-left: 15px;
      }
    }
  }
  > .value.text {
    @include validate-message();
  }
}
</style>
