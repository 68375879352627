<template>
  <div class="clinic-information-input-area">
    <div class="row name">
      <text-input-form
        data-test="name"
        :requiredFlg="adminFlg === 1"
        :placeholder="'30文字まで'"
        :maxlength="30"
        :styles="{ width: '300px' }"
        :disabled="adminFlg === 0"
        :disabledColorWhite="adminFlg === 0"
        v-model="clinic.name"
        >貴院名</text-input-form
      >
    </div>
    <div class="row name-english" v-if="enAvailable">
      <text-input-form
        data-test="name-english"
        :requiredFlg="enAvailable"
        :placeholder="'60文字まで'"
        :maxlength="60"
        :styles="{ width: '300px' }"
        :disabled="adminFlg === 0"
        :disabledColorWhite="adminFlg === 0"
        v-model="clinic.nameEnglish"
        >貴院名（英語）</text-input-form
      >
    </div>
    <div class="row tel">
      <text-input-form
        data-test="tel"
        :requiredFlg="adminFlg === 1"
        :validationRules="{ regexTel: true }"
        :placeholder="'例：09012345678'"
        :maxlength="11"
        :styles="{ width: '160px' }"
        :disabled="adminFlg === 0"
        :disabledColorWhite="adminFlg === 0"
        :fullWidthNumbersToHalfWidthNumbers="true"
        v-model="clinic.tel"
        >電話番号（半角数字）</text-input-form
      >
    </div>
    <div class="row email">
      <text-input-form
        data-test="email"
        :requiredFlg="adminFlg === 1"
        :validationRules="{ emailRule: true }"
        :placeholder="'例：petorelu@email.com'"
        :type="'email'"
        :maxlength="254"
        :styles="{ width: '300px' }"
        :disabled="adminFlg === 0"
        :disabledColorWhite="adminFlg === 0"
        v-model="clinic.email"
        >メールアドレス</text-input-form
      >
    </div>
    <div class="row postal-code">
      <auto-input-form
        data-test="postal-code"
        :value="clinic.postalCode"
        :requiredFlg="adminFlg === 1"
        :validationRules="{
          regexPostalCode: true,
          apiResultPostalCode: apiResultPostalCode
        }"
        :immediate="true"
        :placeholder="'例：1234567'"
        :maxlength="7"
        :inputStyle="{ width: '120px' }"
        :buttonText="'住所自動入力'"
        :buttonStyle="{ width: '140px' }"
        :buttonShowFlg="adminFlg === 1"
        :disabled="adminFlg === 0"
        :disabledColorWhite="adminFlg === 0"
        :fullWidthNumbersToHalfWidthNumbers="true"
        @input="$emit('input-postal-code', $event)"
        @click="$emit('auto-input-address')"
        >郵便番号</auto-input-form
      >
    </div>
    <div class="row prefecture-id">
      <select-box-form
        data-test="prefecture-id"
        :requiredFlg="adminFlg === 1"
        :validationRules="{ requiredRule: true, oneOfRule: prefectures }"
        :selectData="prefectures"
        :disabled="adminFlg === 0"
        :disabledColorWhite="adminFlg === 0"
        v-model.number="clinic.prefectureId"
      >
        都道府県
      </select-box-form>
    </div>
    <div class="row address">
      <text-input-form
        data-test="address"
        :requiredFlg="adminFlg === 1"
        :placeholder="'50文字まで'"
        :maxlength="50"
        :styles="{ width: '300px' }"
        :disabled="adminFlg === 0"
        :disabledColorWhite="adminFlg === 0"
        v-model="clinic.address"
        >市区町村</text-input-form
      >
    </div>
    <div class="row building">
      <text-input-form
        data-test="building"
        :placeholder="'50文字まで'"
        :maxlength="50"
        :styles="{ width: '300px' }"
        :disabled="adminFlg === 0"
        :disabledColorWhite="adminFlg === 0"
        v-model="clinic.building"
        >ビル・マンション名</text-input-form
      >
    </div>
    <div class="row invoice-number">
      <text-input-form
        data-test="invoice-number"
        :note="noteInvoiceNumber"
        :disabled="adminFlg === 0"
        :disabledColorWhite="adminFlg === 0"
        :styles="{ width: '300px' }"
        :validationRules="{ invoiceNumberRule: true }"
        :placeholder="'例：T1234567890123'"
        :maxlength="14"
        v-model="clinic.invoiceNumber"
        >インボイス登録番号</text-input-form
      >
    </div>
    <div class="row anicom-h-cd">
      <text-input-form
        data-test="anicom-h-cd"
        :disabled="adminFlg === 0"
        :disabledColorWhite="adminFlg === 0"
        :styles="{ width: '300px' }"
        :validationRules="{ anicomHCdRule: true }"
        :placeholder="'例：a12345'"
        :maxlength="6"
        v-model="clinic.anicomHCd"
        >アニコム医療機関コード</text-input-form
      >
    </div>
    <div class="row vh-code">
      <text-input-form
        data-test="vh-code"
        :disabled="adminFlg === 0"
        :disabledColorWhite="adminFlg === 0"
        :styles="{ width: '300px' }"
        :validationRules="{ vhCodeRule: true }"
        :placeholder="'例：H12345'"
        :maxlength="6"
        v-model="clinic.vhCode"
        >アイペット病院コード</text-input-form
      >
    </div>
    <div class="row contact-tel">
      <flg-text-input-form
        data-test="contact-tel"
        :requiredFlg="adminFlg === 1 && clinic.contactTelShowFlg === 1"
        :label="'連絡先電話番号'"
        :note="noteTel"
        :flgDisabled="adminFlg === 0"
        :radioButtonData="radioButtonDataTel"
        :flgValue="clinic.contactTelShowFlg"
        :validationRules="{ regexTel: true }"
        :placeholder="'例：09012345678'"
        :maxlength="11"
        :textStyle="{ width: '160px' }"
        :textDisabled="adminFlg === 0"
        :textDisabledColorWhite="adminFlg === 0"
        :textValue="clinic.contactTel"
        :fullWidthNumbersToHalfWidthNumbers="true"
        @input-flg="inputValue('contactTelShowFlg', $event)"
        @input-text="inputValue('contactTel', $event)"
      />
    </div>
    <div class="row contact-email">
      <flg-text-input-form
        data-test="contact-email"
        :requiredFlg="adminFlg === 1 && clinic.contactEmailShowFlg === 1"
        :label="'連絡先メールアドレス'"
        :note="noteEmail"
        :flgDisabled="adminFlg === 0"
        :radioButtonData="radioButtonDataEmail"
        :flgValue="clinic.contactEmailShowFlg"
        :validationRules="{ emailRule: true }"
        :placeholder="'例：petorelu@email.com'"
        :maxlength="254"
        :textStyle="{ width: '300px' }"
        :textDisabled="adminFlg === 0"
        :textDisabledColorWhite="adminFlg === 0"
        :textValue="clinic.contactEmail"
        @input-flg="inputValue('contactEmailShowFlg', $event)"
        @input-text="inputValue('contactEmail', $event)"
      />
    </div>
  </div>
</template>

<script>
import TextInputForm from '@/components/parts/molecules/TextInputForm'
import AutoInputForm from '@/components/parts/molecules/AutoInputForm'
import SelectBoxForm from '@/components/parts/molecules/SelectBoxForm'
import FlgTextInputForm from '@/components/parts/molecules/FlgTextInputForm'
import { mapGetters } from 'vuex'

export default {
  name: 'ClinicInformationInputArea',

  components: {
    TextInputForm,
    AutoInputForm,
    SelectBoxForm,
    FlgTextInputForm
  },

  data() {
    const reservationSetting = this.$store.getters['reservationSetting/getData']
    return {
      noteInvoiceNumber:
        'インボイス制度における適格請求書発行事業者の登録番号です。',
      noteTel:
        '飼主に医院の連絡先を表示する際に使用する電話番号です。\n\n※使用箇所\nログイン画面\n予約登録完了の画面\n予約申請完了の画面\n飼主様情報変更完了の画面\n飼主様情報変更完了のお知らせメール\n飼主・患者詳細画面のパスワード発行の通知メール',
      noteEmail:
        '飼主に医院の連絡先を表示する際に使用するメールアドレスです。\n\n※使用箇所\n飼主様情報変更完了のお知らせメール\n飼主・患者詳細画面のパスワード発行の通知メール',
      radioButtonDataTel: [
        { id: 1, eachValue: 1, labelName: '表示' },
        { id: 2, eachValue: 0, labelName: '非表示' }
      ],
      radioButtonDataEmail: [
        { id: 3, eachValue: 1, labelName: '表示' },
        { id: 4, eachValue: 0, labelName: '非表示' }
      ],
      enAvailable: reservationSetting.englishOptionFlg === 1
    }
  },

  props: {
    adminFlg: { type: Number },
    clinic: { type: Object },
    apiResultPostalCode: {
      type: Object,
      default: () => null
    }
  },

  computed: {
    ...mapGetters({
      prefectures: 'master/getPrefectures'
    })
  },

  methods: {
    inputValue(key, value) {
      this.clinic[key] = value
    }
  }
}
</script>

<style lang="scss" scoped>
.clinic-information-input-area {
  > .row {
    width: 720px;
    margin-bottom: 25px;
    ::v-deep .auto-input-form .heading,
    ::v-deep .text-input-form .heading,
    ::v-deep .select-box-form .heading {
      width: 240px;
    }
    ::v-deep .error {
      width: auto;
      font-feature-settings: 'palt';
    }
  }
}
</style>
