<template>
  <div class="open-date">
    <div class="section base">
      <div class="title">診療日（基本設定）</div>
      <div class="content">
        <open-times-input-area
          :openTimes="editOpenTimes"
          @input-start="inputStart"
          @input-end="inputEnd"
          @check-close="checkClose"
          @input-lunch-start="inputLunchStart"
          @input-lunch-end="inputLunchEnd"
          @check-lunch-open="checkLunchOpen"
          @click-button="convertAllTime"
          @click-lunch-button="convertAllLunchTime"
        />
      </div>
    </div>
    <div class="section exception">
      <div class="title exception">診療日（例外設定）</div>
      <div class="content exception">
        <div class="text red">基本設定より優先されます</div>
        <div class="text black">
          臨時休業の時など、基本設定に対し例外的に設定が必要になった時などに設定してください。
        </div>
        <a
          :href="helpLink"
          target="_blank"
          rel="noopener noreferrer"
          class="text blue"
        >
          <div class="arrow"></div>
          <span class="inner">ネット予約カスタム設定ご利用中のお客様へ</span>
        </a>
        <div class="text">
          ・例外日のパネルの色 ⇒
          <div class="color-plate exceptionday" />
        </div>
        <div class="text panel-color">
          ・祝日のパネルの色 ⇒
          <div class="color-plate holiday" />
        </div>
        <calendar-table
          class="calendar"
          :openTimes="editOpenTimes"
          :specialOpenTimes="notDeletedSpecialOpenTimes"
          @input="remakeEditSpecialOpenTimes"
        />
      </div>
    </div>
    <div class="section button-area">
      <base-button-medium-orange
        class="button"
        v-if="lookOnlyFlg === 0"
        :disabled="!allValidFlg || waitFlg"
        @click="updateData"
        >登録</base-button-medium-orange
      >
    </div>
    <announce-popup
      v-if="alertFlg"
      :title="title"
      :buttons="buttons"
      :type="type"
      :leftAlignMessage="leftAlignMessage"
      @close="alertFlg = false"
      >{{ popupMessage }}</announce-popup
    >
    <unsaved-leave-popup />
  </div>
</template>

<script>
import OpenTimesInputArea from '@/components/parts/organisms/OpenTimesInputArea'
import CalendarTable from '@/components/parts/organisms/CalendarTable.vue'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import { mapGetters } from 'vuex'
import moment from 'moment'
import { removeClinicIdCreateUpdate } from '@/utils/property_filter'
import {
  validateTimes,
  validTimePeriod,
  validateLunchTime
} from '@/utils/open_date'
import HelpLink from '@/components/mixins/HelpLink'

export default {
  name: 'OpenDate',

  components: {
    OpenTimesInputArea,
    CalendarTable,
    BaseButtonMediumOrange,
    AnnouncePopup,
    UnsavedLeavePopup
  },

  mixins: [CheckInputDifference, HelpLink],

  data() {
    return {
      alertFlg: false,
      waitFlg: false,
      title: '',
      buttons: ['閉じる'],
      type: '',
      popupMessage: '',
      leftAlignMessage: '',
      editOpenTimes: [],
      editSpecialOpenTimes: [],
      today: moment().format('YYYYMMDD')
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    timeValidFlg() {
      return validateTimes(this.editOpenTimes)
    },
    periodValidFlg() {
      return validTimePeriod(this.editOpenTimes)
    },
    lunchValidFlg() {
      return validateLunchTime(this.editOpenTimes)
    },
    allValidFlg() {
      return this.timeValidFlg && this.periodValidFlg && this.lunchValidFlg
    },
    notDeletedSpecialOpenTimes() {
      return this.editSpecialOpenTimes.filter(v => v.delFlg !== 1)
    },
    helpLink() {
      return this.mixinHelpLink()
    }
  },

  created() {
    this.setData()
  },

  methods: {
    setData() {
      this.editOpenTimes = removeClinicIdCreateUpdate(
        this.$store.getters['openTimes/getData']
      )
      this.editSpecialOpenTimes = removeClinicIdCreateUpdate(
        this.$store.getters['specialOpenTimes/getData']
      ).filter(obj => this.today <= obj.date)
      this.mixinInputData = {
        openTimes: this.editOpenTimes,
        specialOpenTimes: this.editSpecialOpenTimes
      }
      this.mixinSetInitialData()
    },
    inputStart(start, type) {
      const targetOpenTime = this.editOpenTimes.find(obj => obj.type === type)
      this.$set(targetOpenTime, 'start', start)
    },
    inputEnd(end, type) {
      const targetOpenTime = this.editOpenTimes.find(obj => obj.type === type)
      this.$set(targetOpenTime, 'end', end)
    },
    checkClose(closeFlg, type) {
      const targetOpenTime = this.editOpenTimes.find(obj => obj.type === type)
      this.$set(targetOpenTime, 'closeFlg', closeFlg)
    },
    inputLunchStart(lunchStart, type) {
      const targetOpenTime = this.editOpenTimes.find(obj => obj.type === type)
      this.$set(targetOpenTime, 'lunchStart', lunchStart)
    },
    inputLunchEnd(lunchEnd, type) {
      const targetOpenTime = this.editOpenTimes.find(obj => obj.type === type)
      this.$set(targetOpenTime, 'lunchEnd', lunchEnd)
    },
    checkLunchOpen(lunchOpenFlg, type) {
      const targetOpenTime = this.editOpenTimes.find(obj => obj.type === type)
      this.$set(targetOpenTime, 'lunchOpenFlg', lunchOpenFlg)
    },
    convertAllTime() {
      const baseOpenTime = this.editOpenTimes.find(obj => obj.type === 0)
      this.editOpenTimes.forEach((_, i) => {
        this.$set(this.editOpenTimes[i], 'start', baseOpenTime.start)
        this.$set(this.editOpenTimes[i], 'end', baseOpenTime.end)
      })
    },
    convertAllLunchTime() {
      const baseOpenTime = this.editOpenTimes.find(obj => obj.type === 0)
      this.editOpenTimes.forEach((_, i) => {
        this.$set(this.editOpenTimes[i], 'lunchStart', baseOpenTime.lunchStart)
        this.$set(this.editOpenTimes[i], 'lunchEnd', baseOpenTime.lunchEnd)
      })
    },
    remakeEditSpecialOpenTimes(selectItem) {
      let editSpecialOpenTimes = this.editSpecialOpenTimes
      if (editSpecialOpenTimes.some(obj => obj.date == selectItem.date)) {
        const target = editSpecialOpenTimes.find(
          obj => obj.date === selectItem.date
        )
        if (selectItem.deleteFlg || selectItem.name === '選択解除') {
          if (target.hasOwnProperty('id')) {
            this.$set(target, 'delFlg', 1)
          } else {
            this.$delete(
              editSpecialOpenTimes,
              editSpecialOpenTimes.findIndex(obj => obj.date === target.date)
            )
          }
        } else {
          if (target.hasOwnProperty('id') && target.delFlg === 1) {
            this.$set(target, 'delFlg', 0)
          }
          this.$set(target, 'reservationAvailablePatternId', selectItem.value)
        }
      } else {
        editSpecialOpenTimes.push({
          date: selectItem.date,
          reservationAvailablePatternId: selectItem.value
        })
      }
    },
    removeErrorData() {
      const reservationAvailablePatterns = this.$store.getters[
        'reservationAvailablePatterns/getData'
      ]
      this.editSpecialOpenTimes = this.editSpecialOpenTimes.filter(v1 =>
        reservationAvailablePatterns.some(
          v2 => v2.id === v1.reservationAvailablePatternId
        )
      )
    },
    async updateData() {
      this.waitFlg = true
      const obj = {
        openTimes: this.editOpenTimes,
        specialOpenTimes: this.editSpecialOpenTimes
      }
      const res = await this.$store.dispatch('openTimes/update', obj)
      if (res.status === 200) {
        this.type = 'success'
        this.title = '完了'
        this.popupMessage = '編集しました'
        this.leftAlignMessage = res.leftAlignMessage
        this.setData()
      } else {
        this.type = 'failure'
        this.title = '失敗'
        this.popupMessage =
          res.message === 'no data'
            ? '編集に失敗しました。\n選択した受付時間パターンの中にすでに削除されているものがあります。\n他の受付時間パターンを選択して下さい。'
            : '編集に失敗しました'
        this.leftAlignMessage = ''
        if (res.message === 'no data') {
          this.removeErrorData()
        }
      }
      this.waitFlg = false
      this.alertFlg = true
    }
  }
}
</script>

<style lang="scss" scoped>
.open-date {
  margin-top: 22px;
  > .section {
    > .title {
      font-size: 15px;
      font-weight: bold;
      border-bottom: 1px solid #{$light-grey};
      padding-bottom: 20px;
      &.exception {
        margin-top: 70px;
      }
    }
    > .content {
      margin-top: 30px;
      &.exception {
        margin-top: 23px;
      }
      > .text {
        font-size: 15px;
        word-wrap: break-word;
        display: flex;
        align-items: center;
        &.red {
          color: #{$tomato};
        }
        &.black {
          margin-top: 10px;
        }
        &.blue {
          display: inline-block;
          margin: 20px 0 20px;
          color: #{$light-teal};
          &:hover {
            text-decoration: underline;
          }
        }
        .arrow {
          content: '';
          box-sizing: border-box;
          height: 7px;
          width: 7px;
          top: -2px;
          left: -2px;
          position: relative;
          display: inline-block;
          border-top: solid 2px #{$light-teal};
          border-right: solid 2px #{$light-teal};
          -ms-transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
        }
        .inner {
          margin-left: 8px;
        }
        &.panel-color {
          margin-top: 10px;
        }
        > .color-plate {
          width: 22px;
          height: 22px;
          margin-left: 6px;
          &.exceptionday {
            background-color: #{$pumpkin};
          }
          &.holiday {
            background-color: #{$light-purple};
          }
        }
      }
      > .calendar {
        margin: 30px 0;
        max-width: 1000px;
        min-width: 860px;
      }
    }
    &.button-area {
      display: flex;
      justify-content: center;
      margin: 60px 0;
    }
  }
}
</style>
