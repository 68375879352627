<template>
  <div class="open-times-input-area">
    <div class="area headings">
      <div class="column">
        <div class="item label"></div>
        <div class="item heading">診療時間</div>
        <div class="item button"></div>
      </div>
      <div class="column">
        <div class="item label"></div>
        <div class="item heading">お昼時間</div>
        <div class="item button"></div>
      </div>
    </div>
    <div class="area base">
      <div class="open-time">
        <open-time-input-field
          class="input-field"
          :type="baseOpenTime.type"
          :start="toColon(baseOpenTime.start)"
          :end="toColon(baseOpenTime.end)"
          :lunchStart="toColon(baseOpenTime.lunchStart)"
          :lunchEnd="toColon(baseOpenTime.lunchEnd)"
          @input-start="inputStart"
          @input-end="inputEnd"
          @click-button="clickButton"
          @input-lunch-start="inputLunchStart"
          @input-lunch-end="inputLunchEnd"
          @click-lunch-button="clickLunchButton"
        />
        <div class="errors base">
          <div class="open">
            <div class="message" v-if="baseOpenTime.start >= baseOpenTime.end">
              {{ '終了時間は開始時間より後に設定してください' }}
            </div>
          </div>
          <div class="lunch">
            <div
              class="message"
              v-if="baseOpenTime.lunchStart >= baseOpenTime.lunchEnd"
            >
              {{ '終了時間は開始時間より後に設定してください' }}
            </div>
            <div
              class="message"
              v-if="
                baseOpenTime.lunchStart <= baseOpenTime.start ||
                  baseOpenTime.end <= baseOpenTime.lunchStart ||
                  baseOpenTime.lunchEnd <= baseOpenTime.start ||
                  baseOpenTime.end <= baseOpenTime.lunchEnd
              "
            >
              {{ 'お昼時間は診療時間内に設定してください' }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="area closes">
      <div class="column">
        <div class="item label"></div>
        <div class="item heading"></div>
        <div class="item close normal">休診日</div>
      </div>
      <div class="column">
        <div class="item label"></div>
        <div class="item heading"></div>
        <div class="item close">お昼なし</div>
      </div>
    </div>
    <div class="area open-times">
      <div
        class="open-time"
        v-for="openTime in openTimes.filter(v => v.type !== 0)"
        :key="openTime.id"
      >
        <open-time-input-field
          class="input-field"
          :type="openTime.type"
          :start="toColon(openTime.start)"
          :end="toColon(openTime.end)"
          :closeFlg="toBoolean(openTime.closeFlg)"
          :lunchStart="toColon(openTime.lunchStart)"
          :lunchEnd="toColon(openTime.lunchEnd)"
          :lunchOpenFlg="toBoolean(openTime.lunchOpenFlg)"
          @input-start="inputStart"
          @input-end="inputEnd"
          @check-close="checkClose"
          @input-lunch-start="inputLunchStart"
          @input-lunch-end="inputLunchEnd"
          @check-lunch-open="checkLunchOpen"
        />
        <div class="errors">
          <div class="open">
            <div class="message" v-if="openTime.start >= openTime.end">
              {{ '終了時間は開始時間より後に設定してください' }}
            </div>
          </div>
          <div class="lunch">
            <div
              class="message"
              v-if="openTime.lunchStart >= openTime.lunchEnd"
            >
              {{ '終了時間は開始時間より後に設定してください' }}
            </div>
            <div
              class="message"
              v-if="
                openTime.lunchOpenFlg === 0 &&
                  (openTime.lunchStart <= openTime.start ||
                    openTime.end <= openTime.lunchStart ||
                    openTime.lunchEnd <= openTime.start ||
                    openTime.end <= openTime.lunchEnd)
              "
            >
              {{ 'お昼時間は診療時間内に設定してください' }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OpenTimeInputField from '@/components/parts/molecules/OpenTimeInputField'

export default {
  name: 'OpenTimesInputArea',

  components: {
    OpenTimeInputField
  },

  props: {
    openTimes: { type: Array }
  },

  computed: {
    baseOpenTime() {
      return this.openTimes.find(v => v.type === 0)
    }
  },

  methods: {
    toColon(time) {
      return time.substr(0, 2) + ':' + time.substr(2, 4)
    },
    toBoolean(num) {
      return num === 1 ? true : false
    },
    toTime(colonTime) {
      return ('0000' + colonTime.split(':').join('')).slice(-4)
    },
    toNum(boolean) {
      return boolean === true ? 1 : 0
    },
    inputStart(start, type) {
      this.$emit('input-start', this.toTime(start), type)
    },
    inputEnd(end, type) {
      this.$emit('input-end', this.toTime(end), type)
    },
    checkClose(closeFlg, type) {
      this.$emit('check-close', this.toNum(closeFlg), type)
    },
    inputLunchStart(lunchStart, type) {
      this.$emit('input-lunch-start', this.toTime(lunchStart), type)
    },
    inputLunchEnd(lunchEnd, type) {
      this.$emit('input-lunch-end', this.toTime(lunchEnd), type)
    },
    checkLunchOpen(lunchOpenFlg, type) {
      this.$emit('check-lunch-open', this.toNum(lunchOpenFlg), type)
    },
    clickButton() {
      this.$emit('click-button')
    },
    clickLunchButton() {
      this.$emit('click-lunch-button')
    }
  }
}
</script>

<style lang="scss" scoped>
.open-times-input-area {
  max-width: 1000px;
  min-width: 860px;
  font-size: 15px;
  > .area {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    > .column {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      > .item {
        display: flex;
        justify-content: center;
        align-items: center;
        &.label {
          flex: 1;
          max-width: 120px;
          min-width: 65px;
        }
        &.heading {
          width: 190px;
        }
        &.button,
        &.close {
          justify-content: start;
          width: 160px;
          margin-left: 10px;
        }
        &.normal {
          width: 152px;
          margin-left: 18px;
        }
      }
    }
    &.base {
      width: 100%;
      margin-top: 35px;
      display: flex;
      flex-direction: column;
    }
    > .open-time {
      width: 100%;
    }
    &.closes {
      margin-top: 30px;
    }
    &.open-times {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      margin-top: 13px;
      border: solid #{$light-grey};
      border-width: 1px 1px 1px 1px;
      > .open-time {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 56px;
        border: solid #{$light-grey};
        border-width: 0px 0px 1px 0px;
        &:nth-child(even) {
          background-color: #{$white_f7};
        }
        &:last-child {
          border: none;
        }
        > .input-field {
          margin: 5px 0;
        }
      }
    }
    > .open-time .errors {
      width: 100%;
      display: flex;
      flex-direction: row;
      &.base {
        margin-top: 5px;
      }
      > .open,
      .lunch {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 13px;
        color: #{$tomato};
        > .message {
          margin-bottom: 5px;
        }
      }
    }
  }
}
</style>
