<template>
  <div class="species-input-form">
    <div class="row name">
      <text-input-form
        data-test="name"
        :requiredFlg="true"
        :maxlength="15"
        :placeholder="'15文字まで'"
        :styles="{ width: '350px' }"
        v-model="species.name"
        >種別名</text-input-form
      >
    </div>
    <div class="row name-english" v-if="enAvailable">
      <text-input-form
        data-test="name-english"
        :requiredFlg="true"
        :maxlength="30"
        :placeholder="'30文字まで'"
        :styles="{ width: '350px' }"
        v-model="species.nameEnglish"
        >種別名（英語）</text-input-form
      >
    </div>
  </div>
</template>

<script>
import TextInputForm from '@/components/parts/molecules/TextInputForm'
export default {
  name: 'SpeciesInputForm',
  components: {
    TextInputForm
  },
  props: {
    species: { type: Object }
  },
  data() {
    const reservationSetting = this.$store.getters['reservationSetting/getData']
    return {
      enAvailable: reservationSetting.englishOptionFlg === 1
    }
  }
}
</script>

<style lang="scss" scoped>
.species-input-form {
  > .row ~ .row {
    margin-top: 25px;
  }
}
</style>
