<template>
  <div class="common-setting">
    <div class="wrap-content">
      <div class="fixed-display" v-if="checkPath">
        <div class="setting-title">共通設定</div>
        <div class="setting-tab-title-bar">
          <div v-for="titleObj in tabTitles" :key="titleObj.id">
            <base-setting-tab-title
              :title="titleObj.title"
              :link="titleObj.link"
              :names="titleObj.names"
              :adminOnlyFlg="titleObj.adminOnlyFlg"
            />
          </div>
        </div>
      </div>
      <div class="content-body">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import BaseSettingTabTitle from '@/components/parts/atoms/BaseSettingTabTitle.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'CommonSetting',
  components: {
    BaseSettingTabTitle
  },
  data() {
    const standardTabTitles = [
      {
        id: 1,
        title: '基本設定',
        link: 'base',
        names: ['common-base']
      },
      {
        id: 2,
        title: '診療日',
        link: 'open-date',
        names: ['open-date']
      },
      {
        id: 3,
        title: '医院情報',
        link: 'clinic-information',
        names: ['clinic-information']
      },
      {
        id: 4,
        title: '種別',
        link: 'species',
        names: ['species']
      },
      {
        id: 5,
        title: 'データ取込',
        link: 'data-import',
        names: ['data-import'],
        adminOnlyFlg: true
      }
    ]
    return {
      standardTabTitles
    }
  },
  computed: {
    ...mapGetters({
      karteFlg: 'auth/karteFlg'
    }),
    tabTitles() {
      return this.karteFlg
        ? [
            ...this.standardTabTitles,
            {
              id: 6,
              title: '印刷設定',
              link: 'print-setting',
              names: ['print-setting']
            }
          ]
        : this.standardTabTitles
    },
    checkPath() {
      return this.$route.params.id ||
        this.$route.path === '/settings/common/species/new'
        ? false
        : true
    }
  }
}
</script>
<style lang="scss" scoped>
.common-setting {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  text-align: left;
  > .wrap-content {
    > .fixed-display {
      position: sticky;
      position: -webkit-sticky;
      top: 24px;
      transform: translateY(-24px);
      padding-top: 24px;
      margin-bottom: -24px;
      z-index: 100;
      background-color: #{$white};
      > .setting-title {
        font-size: 20px;
        font-weight: bold;
      }
      > .setting-tab-title-bar {
        display: flex;
        flex-direction: row;
        height: 26px;
        margin-top: 20px;
        padding-bottom: -4px;
        border-bottom: 1px solid;
        border-color: #{$light-grey};
        box-sizing: border-box;
      }
    }
  }
}
</style>
