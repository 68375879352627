<template>
  <div class="common-setting-base">
    <validation-observer class="validation-observer" v-slot="{ invalid }">
      <div class="title">飼主IDの入力補助</div>
      <div class="common-setting-base-form">
        <div class="row check-box">
          <div class="label">入力補助あり</div>
          <div class="field">
            <base-check-box
              :isChecked="commonSetting.ownerShowIdHelperFlg"
              v-model="commonSetting.ownerShowIdHelperFlg"
            />
          </div>
        </div>

        <div class="row">
          <text-input-form
            :validationRules="{ regexShowId: true }"
            :maxlength="20"
            :placeholder="'20文字まで'"
            :disabled="!commonSetting.ownerShowIdHelperFlg"
            :styles="textInputStyles"
            v-model="commonSetting.ownerShowIdPrependText"
            >先頭につける番号</text-input-form
          >
        </div>
        <div class="row check-box">
          <div class="label">通し番号をつける</div>
          <div class="field">
            <base-check-box
              :isChecked="commonSetting.ownerShowIdNumberFlg"
              v-model="commonSetting.ownerShowIdNumberFlg"
              :disabled="!commonSetting.ownerShowIdHelperFlg"
            />
          </div>
        </div>
        <div class="row">
          <select-box-form
            :selectData="selectNumber"
            v-model.number="commonSetting.ownerShowIdLength"
            :disabled="
              !commonSetting.ownerShowIdHelperFlg ||
                !commonSetting.ownerShowIdNumberFlg
            "
          >
            通し番号の桁数
          </select-box-form>
        </div>
      </div>
      <div class="title patient">患者IDの入力補助</div>
      <div class="common-setting-base-form">
        <div class="row">
          <select-box-form
            :selectData="selectData"
            :styles="selectBoxStyles"
            v-model.number="commonSetting.patientShowIdHelperKind"
          >
            入力補助
          </select-box-form>
        </div>
        <div class="row">
          <select-box-form
            :selectData="selectNumber"
            v-model.number="commonSetting.patientShowIdLength"
            :disabled="commonSetting.patientShowIdHelperKind === 0"
          >
            通し番号の桁数
          </select-box-form>
        </div>
        <div class="row">
          <radio-button-form
            :title="'患者ID自動登録'"
            :radioButtonData="radioButtonData"
            v-model="commonSetting.patientShowIdAutoFlg"
            :titleDescription="patientShowIdAutoFlgHint"
            :disabled="commonSetting.patientShowIdHelperKind === 0"
          />
        </div>
      </div>
      <div class="decision-button">
        <base-button-medium-orange
          v-if="lookOnlyFlg === 0"
          :disabled="invalid || waitFlg"
          @click="editData"
          >登録</base-button-medium-orange
        >
      </div>
      <unsaved-leave-popup />
      <announce-popup
        v-if="alertFlg"
        @close="closePopup"
        :title="title"
        :buttons="buttons"
        :type="type"
        >{{ popupMessage }}</announce-popup
      >
    </validation-observer>
  </div>
</template>

<script>
import BaseCheckBox from '@/components/parts/atoms/BaseCheckBox'
import TextInputForm from '@/components/parts/molecules/TextInputForm'
import SelectBoxForm from '@/components/parts/molecules/SelectBoxForm'
import RadioButtonForm from '@/components/parts/molecules/RadioButtonForm.vue'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import { ValidationObserver } from 'vee-validate'
import _ from 'lodash'
import { removeClinicIdDelCreateUpdate } from '@/utils/property_filter'
import { mapGetters } from 'vuex'

export default {
  name: 'CommonSettingBase',

  components: {
    BaseCheckBox,
    TextInputForm,
    SelectBoxForm,
    RadioButtonForm,
    BaseButtonMediumOrange,
    UnsavedLeavePopup,
    AnnouncePopup,
    ValidationObserver
  },

  mixins: [CheckInputDifference],

  data() {
    return {
      commonSetting: {},
      selectNumber: [
        { id: 1, name: 1 },
        { id: 2, name: 2 },
        { id: 3, name: 3 },
        { id: 4, name: 4 },
        { id: 5, name: 5 },
        { id: 6, name: 6 },
        { id: 7, name: 7 },
        { id: 8, name: 8 },
        { id: 9, name: 9 },
        { id: 10, name: 10 }
      ],
      selectData: [
        { id: 0, name: '入力補助なし' },
        {
          id: 1,
          name: '先頭に飼主IDを付与し、後に飼主ごとの患者通し番号をつける'
        },
        { id: 2, name: '医院全体での患者通し番号をつける' }
      ],
      radioButtonData: [
        { id: 1, eachValue: 1, labelName: '有効' },
        { id: 2, eachValue: 0, labelName: '無効' }
      ],
      patientShowIdAutoFlgHint:
        '有効にした場合は、上記の設定によって飼主側の予約システムから登録した患者の患者IDを登録します。\n無効にした場合は、飼主側の予約システムから登録した患者の患者IDを登録しません。',
      textInputStyles: { width: '208px' },
      selectBoxStyles: { width: '400px' },
      alertFlg: false,
      waitFlg: false,
      title: '完了',
      buttons: ['閉じる'],
      type: 'success',
      popupMessage: '編集しました'
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg'
    })
  },

  created() {
    this.setData()
  },

  methods: {
    setData() {
      const data = removeClinicIdDelCreateUpdate(
        _.cloneDeep(this.$store.getters['commonSetting/getData'])
      )
      data.ownerShowIdHelperFlg = data.ownerShowIdHelperFlg ? true : false
      data.ownerShowIdNumberFlg = data.ownerShowIdNumberFlg ? true : false
      this.commonSetting = { ...data }
      this.mixinInputData = this.commonSetting
      this.mixinSetInitialData()
    },
    async editData() {
      this.waitFlg = true
      let sendData = _.cloneDeep(this.commonSetting)
      sendData.ownerShowIdHelperFlg = sendData.ownerShowIdHelperFlg ? 1 : 0
      sendData.ownerShowIdNumberFlg = sendData.ownerShowIdNumberFlg ? 1 : 0
      const res = await this.$store.dispatch('commonSetting/update', sendData)
      if (res === true) {
        this.title = '完了'
        this.type = 'success'
        this.popupMessage = '編集しました'
        this.setData()
      } else {
        this.title = '失敗'
        this.type = 'failure'
        this.popupMessage = '編集に失敗しました'
      }
      this.waitFlg = false
      this.alertFlg = true
    },
    closePopup() {
      this.alertFlg = false
    }
  }
}
</script>
<style lang="scss" scoped>
.common-setting-base {
  > .validation-observer {
    > .title {
      margin: 22px 0 30px;
      font-size: 15px;
      font-weight: bold;
      border-bottom: 1px solid #{$light-grey};
      padding-bottom: 20px;
      &.patient {
        margin: 40px 0 30px;
      }
    }
    > .common-setting-base-form {
      width: 650px;
      > .row {
        width: 600px;
        margin-bottom: 25px;
        &.check-box {
          display: flex;
          align-items: center;
        }
        > .label {
          width: 220px;
          font-size: 15px;
          margin-right: 20px;
        }
      }
    }
    > .decision-button {
      display: flex;
      justify-content: center;
      margin: 60px 0;
    }
  }
}
</style>
