<template>
  <div class="open-time-input-field">
    <div class="column open">
      <div class="item label">{{ days[type] }}</div>
      <div class="item time">
        <time-period-input-field
          :startTime="start"
          :endTime="end"
          @input-start="inputStart"
          @input-end="inputEnd"
        />
      </div>
      <div v-if="type === 0" class="item button">
        <base-button-small-orange @click="clickButton"
          >基本時間に一括変換
        </base-button-small-orange>
      </div>
      <div v-else class="item check">
        <base-check-box :isChecked="closeFlg" @input="checkClose" />
      </div>
    </div>
    <div class="column lunch">
      <div v-if="type === 0" class="item label">基本時間</div>
      <div v-else class="item label"></div>
      <div class="item time">
        <time-period-input-field
          :startTime="lunchStart"
          :endTime="lunchEnd"
          @input-start="inputLunchStart"
          @input-end="inputLunchEnd"
        />
      </div>
      <div v-if="type === 0" class="item button">
        <base-button-small-orange @click="clickLunchButton"
          >基本時間に一括変換
        </base-button-small-orange>
      </div>
      <div v-else class="item check">
        <base-check-box :isChecked="lunchOpenFlg" @input="checkLunchOpen" />
      </div>
    </div>
  </div>
</template>

<script>
import TimePeriodInputField from '@/components/parts/molecules/TimePeriodInputField'
import BaseCheckBox from '@/components/parts/atoms/BaseCheckBox'
import BaseButtonSmallOrange from '@/components/parts/atoms/BaseButtonSmallOrange'

export default {
  name: 'OpenTimeInputField',

  components: { TimePeriodInputField, BaseCheckBox, BaseButtonSmallOrange },

  props: {
    type: { type: Number, default: 0 },
    start: { type: String },
    end: { type: String },
    closeFlg: { type: Boolean },
    lunchStart: { type: String },
    lunchEnd: { type: String },
    lunchOpenFlg: { type: Boolean }
  },

  data() {
    return {
      days: [
        '基本時間',
        '日曜日',
        '月曜日',
        '火曜日',
        '水曜日',
        '木曜日',
        '金曜日',
        '土曜日',
        '祝日'
      ]
    }
  },

  methods: {
    inputStart(start) {
      this.$emit('input-start', start, this.type)
    },
    inputEnd(end) {
      this.$emit('input-end', end, this.type)
    },
    checkClose(closeFlg) {
      this.$emit('check-close', closeFlg, this.type)
    },
    inputLunchStart(lunchStart) {
      this.$emit('input-lunch-start', lunchStart, this.type)
    },
    inputLunchEnd(lunchEnd) {
      this.$emit('input-lunch-end', lunchEnd, this.type)
    },
    checkLunchOpen(lunchOpenFlg) {
      this.$emit('check-lunch-open', lunchOpenFlg, this.type)
    },
    clickButton() {
      this.$emit('click-button')
    },
    clickLunchButton() {
      this.$emit('click-lunch-button')
    }
  }
}
</script>

<style lang="scss" scoped>
.open-time-input-field {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  min-width: 860px;
  > .column {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 430px;
    > .item {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    > .label {
      flex: 1;
      max-width: 120px;
      min-width: 65px;
      font-size: 15px;
    }
    > .time {
      width: 190px;
    }
    > .check {
      justify-content: start;
      width: 140px;
      margin-left: 30px;
    }
    > .button {
      justify-content: start;
      width: 160px;
      margin-left: 10px;
      ::v-deep .base-button-small-orange button {
        width: 150px;
        > span {
          font-size: 13px;
        }
      }
    }
  }
}
@media (max-width: $tablet-width) {
  .open-time-input-field {
    > .column {
      > .button {
        ::v-deep .base-button-small-orange button {
          > span {
            font-size: 10px;
            line-height: 22px;
          }
        }
      }
    }
  }
}
</style>
