const validateTime = time => {
  if (typeof time === 'string' && time.length === 4) {
    const h = parseInt(time.slice(0, 2))
    const m = parseInt(time.slice(2, 4))
    return 0 <= h && h <= 23 && 0 <= m && m <= 59
  } else {
    return false
  }
}

const validateTimes = openTimes => {
  return openTimes.every(
    o =>
      validateTime(o.start) &&
      validateTime(o.end) &&
      validateTime(o.lunchStart) &&
      validateTime(o.lunchEnd)
  )
}

const validTimePeriod = openTimes => {
  return openTimes.every(o => o.start < o.end && o.lunchStart < o.lunchEnd)
    ? true
    : false
}

const validateLunchTime = openTimes => {
  return openTimes.every(o => {
    if (o.lunchOpenFlg === 0) {
      return o.start < o.lunchStart &&
        o.lunchStart < o.end &&
        o.start < o.lunchEnd &&
        o.lunchEnd < o.end
        ? true
        : false
    } else {
      return true
    }
  })
}

module.exports = {
  validateTimes,
  validTimePeriod,
  validateLunchTime
}
