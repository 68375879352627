<template>
  <div class="species-show">
    <div class="link">
      <router-link :to="{ name: 'species' }"><base-back-link /></router-link>
    </div>
    <div class="setting-title">種別詳細</div>
    <div class="wrap-content">
      <div class="button-area">
        <router-link
          :to="{ name: 'species-edit', params: this.id }"
          class="button"
        >
          <base-button-small-white v-if="lookOnlyFlg === 0"
            >編集</base-button-small-white
          >
        </router-link>
        <base-button-small-red
          class="button"
          v-if="notLastSpecies && lookOnlyFlg === 0"
          @click="alertFlg = true"
          >削除</base-button-small-red
        >
      </div>
      <div class="content-title">基本項目</div>
      <div class="content-area">
        <show-data-list
          :displayKeys="displayKeys"
          :keys="keys"
          :data="oneSpecies"
        />
      </div>
    </div>
    <announce-popup
      v-if="alertFlg"
      :title="title"
      :buttons="buttons"
      :disabled="waitFlg"
      :type="type"
      @cancel="alertFlg = false"
      @decision="deleteSpecies"
      @close="closeAlert"
      >{{ popupMessage }}<br />
    </announce-popup>
  </div>
</template>

<script>
import BaseBackLink from '@/components/parts/atoms/BaseBackLink'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseButtonSmallRed from '@/components/parts/atoms/BaseButtonSmallRed'
import ShowDataList from '@/components/parts/organisms/ShowDataList.vue'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { mapGetters } from 'vuex'

export default {
  name: 'SpeciesShow',

  components: {
    BaseBackLink,
    BaseButtonSmallWhite,
    BaseButtonSmallRed,
    ShowDataList,
    AnnouncePopup
  },

  props: {
    id: { type: Number }
  },

  data() {
    return {
      alertFlg: false,
      waitFlg: false,
      title: '',
      buttons: [],
      type: '',
      popupMessage: '',
      lastDataFlg: false
    }
  },

  computed: {
    ...mapGetters({
      getSpecies: 'species/getDataById',
      species: 'species/getData',
      lookOnlyFlg: 'auth/lookOnlyFlg',
      reservationSetting: 'reservationSetting/getData'
    }),
    notLastSpecies() {
      return this.species.length > 1
    },
    enAvailable() {
      return this.reservationSetting.englishOptionFlg === 1
    },
    displayKeys() {
      return this.enAvailable ? ['種別名', '種別名（英語）'] : ['種別名']
    },
    keys() {
      return this.enAvailable ? ['name', 'nameEnglish'] : ['name']
    },
    oneSpecies() {
      return this.getSpecies(this.id)
    }
  },

  created() {
    this.initializeAlert()
  },

  methods: {
    initializeAlert() {
      this.alertFlg = false
      this.title = '注意'
      this.buttons = ['削除しない', '削除する']
      this.type = 'alert'
      this.popupMessage = '削除してもよろしいですか？'
    },
    async deleteSpecies() {
      this.waitFlg = true
      const result = await this.$store.dispatch('species/delete', this.id)
      if (result === true) {
        this.title = '完了'
        this.buttons = ['閉じる']
        this.type = 'success'
        this.popupMessage = '削除しました'
      } else {
        this.title = '失敗'
        this.type = 'failure'
        this.buttons = ['閉じる']
        if (result === 'last species') {
          this.popupMessage =
            '種別名は一個以上が必要になる為、削除に失敗しました。'
          this.lastDataFlg = true
        } else {
          this.popupMessage = '削除に失敗しました'
        }
      }
      this.waitFlg = false
    },
    closeAlert() {
      this.alertFlg = false
      if (!this.lastDataFlg) {
        this.$router.push({ name: 'species' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.species-show {
  width: 100%;
  min-width: 614px;
  text-align: left;
  > .link {
    margin-left: -26px;
  }
  > .setting-title {
    font-size: 20px;
    font-weight: bold;
    border-bottom: 1px solid #{$light-grey};
    padding-bottom: 20px;
    margin-top: 20px;
  }
  > .wrap-content {
    margin-top: 20px;
    > .button-area {
      display: flex;
      justify-content: flex-end;
      > .button {
        margin-left: 20px;
      }
    }
    > .content-title {
      font-size: 15px;
      font-weight: bold;
      border-bottom: 1px solid #{$light-grey};
      padding-bottom: 20px;
      margin-top: 20px;
    }
    > .content-area {
      margin-top: 25px;
    }
  }
}
</style>
