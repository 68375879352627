<template>
  <div class="print-setting">
    <div class="general">
      <validation-observer
        tag="div"
        class="section input-form"
        v-slot="{ invalid }"
      >
        <div class="heading">通常の印刷設定</div>
        <div class="form">
          <div class="form-item">
            <div class="label">デフォルト用紙サイズ</div>
            <div class="input-field">
              <div class="radio-buttons">
                <base-radio-button
                  v-for="datum in paperSizeData"
                  :key="'paper-size' + datum.id"
                  :option="datum"
                  v-model="inputGeneral.defaultPaperSize"
                />
              </div>
            </div>
          </div>
          <div class="form-item">
            <div class="label">医院ロゴ</div>
            <div class="input-field">
              <div class="image-wrap" :class="{ border: !inputGeneral.image }">
                <base-image-uploader
                  :image="inputGeneral.image"
                  :keyword="'image'"
                  :maxWidthOrHeight="300"
                  :placeholderStyles="{
                    width: '300px',
                    height: '110px',
                    border: 'unset'
                  }"
                  :imageStyles="{
                    maxWidth: '300px',
                    height: '110px',
                    objectFit: 'contain'
                  }"
                  @input="inputImage"
                  @delete="deleteImage"
                  @uploadingImg="setUploadingImg"
                />
              </div>
            </div>
          </div>
          <div class="form-item">
            <div class="label">
              医院印鑑
              <div class="footnote-mark">※1</div>
            </div>
            <div class="input-field">
              <div class="image-wrap" :class="{ border: !inputGeneral.stamp }">
                <base-image-uploader
                  :image="inputGeneral.stamp"
                  :keyword="'stamp'"
                  :maxWidthOrHeight="300"
                  :placeholderStyles="{
                    width: '300px',
                    height: '110px',
                    border: 'unset'
                  }"
                  :imageStyles="{
                    maxWidth: '300px',
                    height: '110px',
                    objectFit: 'contain'
                  }"
                  @input="inputStamp"
                  @delete="deleteStamp"
                  @uploadingImg="setUploadingImg"
                />
              </div>
            </div>
          </div>
          <div class="form-item">
            <div class="label">医院名</div>
            <div class="input-field">
              <div class="radio-buttons">
                <base-radio-button
                  v-for="datum in printClinicNameData"
                  :key="'clinic-name-flg' + datum.id"
                  :option="datum"
                  v-model="inputGeneral.printClinicNameFlg"
                />
              </div>
            </div>
          </div>
          <div class="form-item">
            <div class="label">
              医院電話番号
              <div class="footnote-mark">※2</div>
            </div>
            <div class="input-field">
              <div class="radio-buttons">
                <base-radio-button
                  v-for="datum in printClinicTelData"
                  :key="'clinic-tel-flg' + datum.id"
                  :option="datum"
                  v-model="inputGeneral.printClinicTelFlg"
                />
              </div>
            </div>
          </div>
          <div class="form-item">
            <div class="label">
              医院メールアドレス
              <div class="footnote-mark">※2</div>
            </div>
            <div class="input-field">
              <div class="radio-buttons">
                <base-radio-button
                  v-for="datum in printClinicEmailData"
                  :key="'clinic-email-flg' + datum.id"
                  :option="datum"
                  v-model="inputGeneral.printClinicEmailFlg"
                />
              </div>
            </div>
          </div>
          <div class="form-item">
            <div class="label">
              医院住所
              <div class="footnote-mark">※2</div>
            </div>
            <div class="input-field">
              <div class="radio-buttons">
                <base-radio-button
                  v-for="datum in printClinicAddressData"
                  :key="'clinic-address-flg' + datum.id"
                  :option="datum"
                  v-model="inputGeneral.printClinicAddressFlg"
                />
              </div>
            </div>
          </div>
          <div class="form-item">
            <div class="label">
              自己負担額のバーコード
              <hint-balloon :note="noteBarcodeFlg" />
              <div class="footnote-mark">※2</div>
            </div>
            <div class="input-field">
              <div class="radio-buttons">
                <base-radio-button
                  v-for="datum in barcodeData"
                  :key="'barcode-flg' + datum.id"
                  :option="datum"
                  v-model="inputGeneral.barcodeFlg"
                />
              </div>
            </div>
          </div>
          <div class="form-item">
            <text-input-form
              data-test="input barcode-prefix"
              :requiredFlg="true"
              :validationRules="{ numericString: { length: 6 } }"
              :maxlength="6"
              :headingStyle="{ width: '300px' }"
              :bodyStyle="{ width: '300px' }"
              :styles="{ width: '120px' }"
              :fullWidthNumbersToHalfWidthNumbers="true"
              :note="noteBarcodePrefix"
              :footnoteMark="'※2'"
              v-model="inputGeneral.barcodePrefix"
              >バーコードの上6桁</text-input-form
            >
          </div>
          <div class="footnote">
            ※1）予防接種証明書印刷でのみ設定が反映されます。<br />
            ※2）診療明細印刷でのみ設定が反映されます。
          </div>
        </div>
        <div class="registration">
          <base-button-medium-orange
            v-if="lookOnlyFlg === 0"
            data-test="registration-button general"
            :disabled="invalid || waitFlg || uploadingImg"
            @click="updateData('general')"
            >登録</base-button-medium-orange
          >
        </div>
      </validation-observer>
      <div class="section preview">
        <div class="heading">プレビュー</div>
        <div class="preview">
          <base-button-border-orange
            :disabled="generalDiffFlg"
            :styles="{ width: '120px', height: '44px' }"
            @click="openPrintPopup('medicalPayment')"
            >診療明細印刷</base-button-border-orange
          >
          <base-button-border-orange
            :disabled="generalDiffFlg"
            :styles="{ width: '120px', height: '44px' }"
            @click="openPrintPopup('estimate')"
            >見積書印刷</base-button-border-orange
          >
          <base-button-border-orange
            :disabled="generalDiffFlg"
            :styles="{ width: '120px', height: '44px' }"
            @click="openPrintPopup('prescription')"
            >処方箋印刷</base-button-border-orange
          >
          <base-button-border-orange
            :disabled="generalDiffFlg"
            :styles="{ width: '120px', height: '44px' }"
            @click="openPrintPopup('examination')"
            >検査印刷</base-button-border-orange
          >
          <base-button-border-orange
            :disabled="generalDiffFlg"
            :styles="{ width: '120px', height: '44px' }"
            @click="openPrintPopup('vaccinationCertificate')"
            >予防接種証明書<br />印刷</base-button-border-orange
          >
          <base-button-border-orange
            :disabled="generalDiffFlg"
            :styles="{ width: '120px', height: '44px' }"
            @click="openPrintPopup('receipt')"
            >領収書印刷<br />（控え無し）</base-button-border-orange
          >
          <base-button-border-orange
            :disabled="generalDiffFlg"
            :styles="{ width: '120px', height: '44px' }"
            @click="openPrintPopup('receipt', true)"
            >領収書印刷<br />（控え有り）</base-button-border-orange
          >
        </div>
      </div>
    </div>
    <div class="address-sheet">
      <validation-observer
        tag="div"
        class="section input-form"
        v-slot="{ errors, invalid }"
      >
        <div class="heading">宛名シートの印刷設定</div>
        <div class="form">
          <div class="form-item">
            <div class="label">デフォルト用紙サイズ</div>
            <div class="input-field">
              <div class="radio-buttons">
                <base-radio-button
                  v-for="datum in addressPaperSizeData"
                  :key="'address-paper-size' + datum.id"
                  :option="datum"
                  v-model="inputAddressSheet.addressSheetPaperSize"
                />
              </div>
            </div>
          </div>
          <div class="form-item">
            <div class="label">印刷の向き</div>
            <div class="input-field">
              <div class="radio-buttons">
                <base-radio-button
                  v-for="datum in addressLayoutData"
                  :key="'address-layout' + datum.id"
                  :option="datum"
                  :disabled="inputAddressSheet.addressSheetPaperSize === 'PC'"
                  v-model="inputAddressSheet.addressSheetVerticalFlg"
                />
              </div>
            </div>
          </div>
          <div class="form-item">
            <div class="label">レイアウト</div>
            <div class="input-field">
              <div class="two-values">
                <select-box-form
                  :selectData="selectRowNumber"
                  :styles="{ width: '60px' }"
                  :disabled="inputAddressSheet.addressSheetPaperSize === 'PC'"
                  v-model="inputAddressSheet.addressSheetRows"
                  >行数</select-box-form
                >
                <select-box-form
                  :selectData="selectColumnNumber"
                  :styles="{ width: '60px' }"
                  :disabled="inputAddressSheet.addressSheetPaperSize === 'PC'"
                  v-model="inputAddressSheet.addressSheetColumns"
                  >列数</select-box-form
                >
              </div>
            </div>
          </div>
          <div class="form-item">
            <div class="label">
              余白（mm）<span class="hint" @click="showHintFlg = !showHintFlg"
                >?</span
              >
            </div>
            <div class="input-field">
              <div class="two-values">
                <text-input-form
                  data-test="input top-bottom-margins"
                  :vid="'topBottomMargins'"
                  :maxlength="3"
                  :validationRules="{ requiredRule: true, intRule: true }"
                  :styles="{ width: '60px' }"
                  :fullWidthNumbersToHalfWidthNumbers="true"
                  :disabled="inputAddressSheet.addressSheetPaperSize === 'PC'"
                  v-model.number="
                    inputAddressSheet.addressSheetTopBottomMargins
                  "
                  @blur="blur('addressSheetTopBottomMargins')"
                  >上下余白</text-input-form
                >
                <text-input-form
                  data-test="input left-right-margins"
                  :vid="'leftRightMargins'"
                  :maxlength="3"
                  :validationRules="{ requiredRule: true, intRule: true }"
                  :styles="{ width: '60px' }"
                  :fullWidthNumbersToHalfWidthNumbers="true"
                  :disabled="inputAddressSheet.addressSheetPaperSize === 'PC'"
                  v-model.number="
                    inputAddressSheet.addressSheetLeftRightMargins
                  "
                  @blur="blur('addressSheetLeftRightMargins')"
                  >左右余白</text-input-form
                >
              </div>
            </div>
          </div>
          <div class="form-item">
            <div class="label">
              間隔（mm）<span class="hint" @click="showHintFlg = !showHintFlg"
                >?</span
              >
            </div>
            <div class="input-field">
              <div class="two-values">
                <text-input-form
                  data-test="input vertical-margins"
                  :vid="'verticalMargins'"
                  :maxlength="3"
                  :validationRules="{ requiredRule: true, intRule: true }"
                  :styles="{ width: '60px' }"
                  :fullWidthNumbersToHalfWidthNumbers="true"
                  :disabled="inputAddressSheet.addressSheetPaperSize === 'PC'"
                  v-model.number="inputAddressSheet.addressSheetVerticalMargins"
                  @blur="blur('addressSheetVerticalMargins')"
                  >行間隔</text-input-form
                >
                <text-input-form
                  data-test="input horizontal-margins"
                  :vid="'horizontalMargins'"
                  :maxlength="3"
                  :validationRules="{ requiredRule: true, intRule: true }"
                  :styles="{ width: '60px' }"
                  :fullWidthNumbersToHalfWidthNumbers="true"
                  :disabled="inputAddressSheet.addressSheetPaperSize === 'PC'"
                  v-model.number="
                    inputAddressSheet.addressSheetHorizontalMargins
                  "
                  @blur="blur('addressSheetHorizontalMargins')"
                  >列間隔</text-input-form
                >
              </div>
            </div>
          </div>
          <div class="form-item">
            <div class="label">はがきでの郵便番号の位置（mm）</div>
            <div class="input-field">
              <div class="two-values">
                <text-input-form
                  data-test="input vertical-margins"
                  :vid="'postCardOwnerPostalCodeVerticalPosition'"
                  :maxlength="3"
                  :validationRules="{ requiredRule: true, intRule: true }"
                  :styles="{ width: '60px' }"
                  :fullWidthNumbersToHalfWidthNumbers="true"
                  :disabled="inputAddressSheet.addressSheetPaperSize !== 'PC'"
                  v-model.number="
                    inputAddressSheet.postCardOwnerPostalCodeVerticalPosition
                  "
                  @blur="blur('postCardOwnerPostalCodeVerticalPosition')"
                  >上</text-input-form
                >
                <text-input-form
                  data-test="input horizontal-margins"
                  :vid="'postCardOwnerPostalCodeHorizontalPosition'"
                  :maxlength="3"
                  :validationRules="{ requiredRule: true, intRule: true }"
                  :styles="{ width: '60px' }"
                  :fullWidthNumbersToHalfWidthNumbers="true"
                  :disabled="inputAddressSheet.addressSheetPaperSize !== 'PC'"
                  v-model.number="
                    inputAddressSheet.postCardOwnerPostalCodeHorizontalPosition
                  "
                  @blur="blur('postCardOwnerPostalCodeHorizontalPosition')"
                  >左</text-input-form
                >
              </div>
            </div>
          </div>
          <div class="form-item">
            <div class="label">はがきでの飼主住所の位置（mm）</div>
            <div class="input-field">
              <div class="two-values">
                <text-input-form
                  data-test="input vertical-margins"
                  :vid="'postCardOwnerAddressVerticalPosition'"
                  :maxlength="3"
                  :validationRules="{ requiredRule: true, intRule: true }"
                  :styles="{ width: '60px' }"
                  :fullWidthNumbersToHalfWidthNumbers="true"
                  :disabled="inputAddressSheet.addressSheetPaperSize !== 'PC'"
                  v-model.number="
                    inputAddressSheet.postCardOwnerAddressVerticalPosition
                  "
                  @blur="blur('postCardOwnerAddressVerticalPosition')"
                  >上</text-input-form
                >
                <text-input-form
                  data-test="input horizontal-margins"
                  :vid="'postCardOwnerAddressHorizontalPosition'"
                  :maxlength="3"
                  :validationRules="{ requiredRule: true, intRule: true }"
                  :styles="{ width: '60px' }"
                  :fullWidthNumbersToHalfWidthNumbers="true"
                  :disabled="inputAddressSheet.addressSheetPaperSize !== 'PC'"
                  v-model.number="
                    inputAddressSheet.postCardOwnerAddressHorizontalPosition
                  "
                  @blur="blur('postCardOwnerAddressHorizontalPosition')"
                  >左</text-input-form
                >
              </div>
            </div>
          </div>
          <div class="form-item">
            <div class="label">はがきでの飼主名の位置（mm）</div>
            <div class="input-field">
              <div class="two-values">
                <text-input-form
                  data-test="input vertical-margins"
                  :vid="'postCardOwnerNameVerticalPosition'"
                  :maxlength="3"
                  :validationRules="{ requiredRule: true, intRule: true }"
                  :styles="{ width: '60px' }"
                  :fullWidthNumbersToHalfWidthNumbers="true"
                  :disabled="inputAddressSheet.addressSheetPaperSize !== 'PC'"
                  v-model.number="
                    inputAddressSheet.postCardOwnerNameVerticalPosition
                  "
                  @blur="blur('postCardOwnerNameVerticalPosition')"
                  >上</text-input-form
                >
                <text-input-form
                  data-test="input horizontal-margins"
                  :vid="'postCardOwnerNameHorizontalPosition'"
                  :maxlength="3"
                  :validationRules="{ requiredRule: true, intRule: true }"
                  :styles="{ width: '60px' }"
                  :fullWidthNumbersToHalfWidthNumbers="true"
                  :disabled="inputAddressSheet.addressSheetPaperSize !== 'PC'"
                  v-model.number="
                    inputAddressSheet.postCardOwnerNameHorizontalPosition
                  "
                  @blur="blur('postCardOwnerNameHorizontalPosition')"
                  >左</text-input-form
                >
              </div>
            </div>
          </div>
          <div class="form-item">
            <div class="label">はがきでの医院情報の位置（mm）</div>
            <div class="input-field">
              <div class="two-values">
                <text-input-form
                  data-test="input vertical-margins"
                  :vid="'postCardSenderVerticalPosition'"
                  :maxlength="3"
                  :validationRules="{ requiredRule: true, intRule: true }"
                  :styles="{ width: '60px' }"
                  :fullWidthNumbersToHalfWidthNumbers="true"
                  :disabled="inputAddressSheet.addressSheetPaperSize !== 'PC'"
                  v-model.number="
                    inputAddressSheet.postCardSenderVerticalPosition
                  "
                  @blur="blur('postCardSenderVerticalPosition')"
                  >上</text-input-form
                >
                <text-input-form
                  data-test="input horizontal-margins"
                  :vid="'postCardSenderHorizontalPosition'"
                  :maxlength="3"
                  :validationRules="{ requiredRule: true, intRule: true }"
                  :styles="{ width: '60px' }"
                  :fullWidthNumbersToHalfWidthNumbers="true"
                  :disabled="inputAddressSheet.addressSheetPaperSize !== 'PC'"
                  v-model.number="
                    inputAddressSheet.postCardSenderHorizontalPosition
                  "
                  @blur="blur('postCardSenderHorizontalPosition')"
                  >左</text-input-form
                >
              </div>
            </div>
          </div>
          <div class="form-item">
            <div class="label">はがきでの医院の郵便番号の位置（mm）</div>
            <div class="input-field">
              <div class="two-values">
                <text-input-form
                  data-test="input vertical-margins"
                  :vid="'postCardSenderPostalCodeVerticalPosition'"
                  :maxlength="3"
                  :validationRules="{ requiredRule: true, intRule: true }"
                  :styles="{ width: '60px' }"
                  :fullWidthNumbersToHalfWidthNumbers="true"
                  :disabled="inputAddressSheet.addressSheetPaperSize !== 'PC'"
                  v-model.number="
                    inputAddressSheet.postCardSenderPostalCodeVerticalPosition
                  "
                  @blur="blur('postCardSenderPostalCodeVerticalPosition')"
                  >上</text-input-form
                >
                <text-input-form
                  data-test="input horizontal-margins"
                  :vid="'postCardSenderPostalCodeHorizontalPosition'"
                  :maxlength="3"
                  :validationRules="{ requiredRule: true, intRule: true }"
                  :styles="{ width: '60px' }"
                  :fullWidthNumbersToHalfWidthNumbers="true"
                  :disabled="inputAddressSheet.addressSheetPaperSize !== 'PC'"
                  v-model.number="
                    inputAddressSheet.postCardSenderPostalCodeHorizontalPosition
                  "
                  @blur="blur('postCardSenderPostalCodeHorizontalPosition')"
                  >左</text-input-form
                >
              </div>
            </div>
          </div>
          <div class="post-card-sender-check">
            <base-check-box
              :isChecked="inputAddressSheet.postCardSenderFlg"
              :labelText="'はがきでの医院情報を印刷する'"
              :disabled="inputAddressSheet.addressSheetPaperSize !== 'PC'"
              v-model="inputAddressSheet.postCardSenderFlg"
            />
          </div>
          <div v-if="showHintFlg" class="layout-image">
            <img
              alt="address-sheet-margins"
              src="@/assets/images/address_sheet_margins.png"
              width="400px"
              height="320px"
            />
          </div>
        </div>
        <div v-if="getSizeErrorMessage(errors) !== ''" class="size-error">
          <span class="text" v-html="getSizeErrorMessage(errors)"></span>
        </div>
        <div class="registration">
          <base-button-medium-orange
            v-if="lookOnlyFlg === 0"
            :disabled="
              invalid ||
                waitFlg ||
                invalidAddressSheetWidth ||
                invalidAddressSheetHeight
            "
            @click="updateData('addressSheet')"
            >登録</base-button-medium-orange
          >
        </div>
      </validation-observer>
      <div class="section preview">
        <div class="heading">プレビュー</div>
        <div class="preview">
          <base-button-border-orange
            :disabled="addressSheetDiffFlg"
            :styles="{ width: '120px', height: '44px' }"
            @click="openPrintPopup('addressSheet')"
            >宛名シート印刷</base-button-border-orange
          >
        </div>
      </div>
    </div>
    <announce-popup
      v-if="alertFlg"
      :title="title"
      :buttons="buttons"
      :type="type"
      @close="closePopup"
      >{{ popupMessage }}</announce-popup
    >
    <unsaved-leave-popup />
  </div>
</template>

<script>
import BaseRadioButton from '@/components/parts/atoms/BaseRadioButton'
import BaseImageUploader from '@/components/parts/atoms/BaseImageUploader'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import BaseButtonBorderOrange from '@/components/parts/atoms/BaseButtonBorderOrange'
import BaseCheckBox from '@/components/parts/atoms/BaseCheckBox'
import HintBalloon from '@/components/parts/atoms/HintBalloon'
import SelectBoxForm from '@/components/parts/molecules/SelectBoxForm'
import TextInputForm from '@/components/parts/molecules/TextInputForm'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import { printReceipt } from '@/utils/print_receipt'
import { printEstimate } from '@/utils/print_estimate'
import { printMedicalPayment } from '@/utils/print_medical_payment'
import { printPrescription } from '@/utils/print_prescription'
import { printExamination } from '@/utils/print_examination'
import { printVaccinationCertificate } from '@/utils/print_vaccination_certificate'
import { printAddressSheet } from '@/utils/print_address_sheet'
import { PAPER_SIZES } from '@/utils/define'
import { makeBirthdayJp, calcAge } from '@/utils/patient_info'
import { ValidationObserver } from 'vee-validate'
import { mapGetters } from 'vuex'
import _ from 'lodash'
import moment from 'moment'
import dedent from 'dedent'

const today = moment().format('Y年M月D日')

export default {
  name: 'PrintSetting',

  components: {
    BaseRadioButton,
    BaseImageUploader,
    BaseButtonMediumOrange,
    BaseButtonBorderOrange,
    BaseCheckBox,
    HintBalloon,
    SelectBoxForm,
    TextInputForm,
    AnnouncePopup,
    UnsavedLeavePopup,
    ValidationObserver
  },

  mixins: [CheckInputDifference],

  data() {
    return {
      inputGeneral: {},
      inputAddressSheet: {},
      paperSizeData: [
        { id: 1, eachValue: 'A4', labelName: 'A4' },
        { id: 2, eachValue: 'B5', labelName: 'B5' },
        { id: 3, eachValue: 'A5', labelName: 'A5' }
      ],
      printClinicNameData: [
        { id: 4, eachValue: 1, labelName: '表示する' },
        { id: 5, eachValue: 0, labelName: '表示しない' }
      ],
      printClinicTelData: [
        { id: 6, eachValue: 1, labelName: '表示する' },
        { id: 7, eachValue: 0, labelName: '表示しない' }
      ],
      printClinicEmailData: [
        { id: 8, eachValue: 1, labelName: '表示する' },
        { id: 9, eachValue: 0, labelName: '表示しない' }
      ],
      printClinicAddressData: [
        { id: 10, eachValue: 1, labelName: '表示する' },
        { id: 11, eachValue: 0, labelName: '表示しない' }
      ],
      addressPaperSizeData: [
        { id: 12, eachValue: 'A4', labelName: 'A4' },
        { id: 13, eachValue: 'B5', labelName: 'B5' },
        { id: 14, eachValue: 'A5', labelName: 'A5' },
        { id: 15, eachValue: 'PC', labelName: 'はがき' }
      ],
      addressLayoutData: [
        { id: 16, eachValue: 1, labelName: '縦' },
        { id: 17, eachValue: 0, labelName: '横' }
      ],
      barcodeData: [
        { id: 18, eachValue: 1, labelName: '表示する' },
        { id: 19, eachValue: 0, labelName: '表示しない' }
      ],
      cellWidth: undefined,
      cellHeight: undefined,
      alertFlg: false,
      waitFlg: false,
      title: '',
      buttons: [],
      type: '',
      popupMessage: '',
      generalAttributes: [
        'defaultPaperSize',
        'image',
        'stamp',
        'printClinicNameFlg',
        'printClinicTelFlg',
        'printClinicEmailFlg',
        'printClinicAddressFlg',
        'barcodeFlg',
        'barcodePrefix'
      ],
      addressSheetAttributes: [
        'addressSheetPaperSize',
        'addressSheetVerticalFlg',
        'addressSheetRows',
        'addressSheetColumns',
        'addressSheetTopBottomMargins',
        'addressSheetLeftRightMargins',
        'addressSheetVerticalMargins',
        'addressSheetHorizontalMargins',
        'postCardSenderFlg',
        'postCardOwnerPostalCodeVerticalPosition',
        'postCardOwnerPostalCodeHorizontalPosition',
        'postCardOwnerNameVerticalPosition',
        'postCardOwnerNameHorizontalPosition',
        'postCardOwnerAddressVerticalPosition',
        'postCardOwnerAddressHorizontalPosition',
        'postCardSenderVerticalPosition',
        'postCardSenderHorizontalPosition',
        'postCardSenderPostalCodeVerticalPosition',
        'postCardSenderPostalCodeHorizontalPosition'
      ],
      showHintFlg: false,
      uploadingImg: false,
      noteBarcodeFlg: dedent`
        診療明細書の印刷では、飼主の自己負担額をバーコード形式（EAN13）でも記載することが可能です。
        記載されるバーコードは13桁の数字からなります。

          1-6桁目：自由な数字を設定可能
          7-12桁目：飼主の自己負担額
          13桁目：チェックレジット

        飼主の自己負担額とチェックレジットの値は計算により自動で設定されます。
        ※金額が7桁以上になる場合はバーコードを利用できません。`,
      noteBarcodePrefix: dedent`
        飼主の自己負担額のバーコードで使用される値です。`,
      // 印刷プレビュー用のモックデータ
      owner: {
        id: 1,
        lastName: 'プレビュー',
        firstName: '飼主',
        prefectureId: 13,
        postalCode: '1000000',
        address: '〇〇〇市〇〇〇町〇〇〇丁目',
        building: 'プレビュービル〇〇〇号室'
      },
      patient: { name: 'プレビュー患者', showId: '1', ownerId: 1 },
      displayPatient: {
        name: 'プレビュー患者',
        showId: '1-1',
        birthday: moment()
          .add(-15, 'M')
          .format('YYYYMMDD'),
        speciesName: 'プレビュー種別',
        breed: 'プレビュー品種',
        sexName: '♂',
        image: 'preview'
      },
      medicalPayment: {
        pledgeRate: 0,
        treatmentItems: [],
        diseaseName: 'プレビュー診断',
        onsetDate: today,
        date: today,
        staffName: 'プレビュースタッフ',
        discountPrice: 0,
        discountRate: 0,
        burdenAmount: 1000
      },
      estimate: {
        insuranceFlg: 0,
        treatmentItems: [],
        diseaseName: 'プレビュー診断',
        date: today,
        discountPrice: 0,
        discountRate: 0,
        pledgeRate: 0
      },
      prescriptionItems: [
        { medicineName: 'プレビュー薬剤物品', prescription: 'プレビュー処方箋' }
      ],
      examinationItems: [
        [
          {
            topicClassName: 'プレビュー項目区分',
            topicName: 'プレビュー項目1',
            resultUnit: '0',
            reference: '',
            comment: '',
            speciesName: 'プレビュー種別'
          },
          {
            topicClassName: 'プレビュー項目区分',
            topicName: 'プレビュー項目2',
            resultUnit: '0',
            reference: '',
            comment: '',
            speciesName: 'プレビュー種別'
          }
        ]
      ]
    }
  },

  computed: {
    ...mapGetters({
      clinic: 'clinic/getData',
      getMasterDatum: 'master/getDataById',
      prefectures: 'master/getPrefectures',
      printSetting: 'printSetting/getData',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    selectRowNumber() {
      return [...Array(11).keys()].map(i => {
        return { id: i + 1, name: i + 1 }
      })
    },
    selectColumnNumber() {
      return [...Array(6).keys()].map(i => {
        return { id: i + 1, name: i + 1 }
      })
    },
    invalidAddressSheetWidth() {
      const minCellWidth = 48.3
      return this.cellWidth < minCellWidth
    },
    invalidAddressSheetHeight() {
      // 高さ210mmで40行入力できたので1行あたり5.25mmとした。
      const minCellHeight = 5.25 * 5
      return this.cellHeight < minCellHeight
    },
    generalDiffFlg() {
      return !_.isEqual(
        this.mixinInitialData.printSettingGeneral,
        this.mixinInputData.printSettingGeneral
      )
    },
    addressSheetDiffFlg() {
      return !_.isEqual(
        this.mixinInitialData.printSettingAddressSheet,
        this.mixinInputData.printSettingAddressSheet
      )
    }
  },

  watch: {
    inputAddressSheet: {
      deep: true,
      handler() {
        const { addressSheetPaperSize: paperSize } = this.inputAddressSheet

        const {
          addressSheetVerticalFlg: verticalFlg,
          addressSheetRows: rows,
          addressSheetColumns: columns,
          addressSheetTopBottomMargins: topBottomMargins,
          addressSheetLeftRightMargins: leftRightMargins,
          addressSheetVerticalMargins: verticalMargins,
          addressSheetHorizontalMargins: horizontalMargins
        } =
          paperSize !== 'PC'
            ? this.inputAddressSheet
            : {
                addressSheetVerticalFlg: 1,
                addressSheetRows: 1,
                addressSheetColumns: 1,
                addressSheetTopBottomMargins: 5,
                addressSheetLeftRightMargins: 5,
                addressSheetVerticalMargins: 0,
                addressSheetHorizontalMargins: 0
              }
        const paperWidth = verticalFlg
          ? PAPER_SIZES[paperSize]?.x
          : PAPER_SIZES[paperSize]?.y
        const paperHeight = verticalFlg
          ? PAPER_SIZES[paperSize]?.y
          : PAPER_SIZES[paperSize]?.x
        const availableWidth =
          paperWidth - leftRightMargins * 2 - horizontalMargins * (columns - 1)
        const availableHeight =
          paperHeight - topBottomMargins * 2 - verticalMargins * (rows - 1)
        this.cellWidth = availableWidth / columns
        this.cellHeight = availableHeight / rows
      }
    }
  },

  created() {
    this.setData('all')
  },

  methods: {
    getSizeErrorMessage(errors) {
      const postCardFlg = this.printSetting.addressSheetPaperSize === 'PC'
      const makeMessage = (textA, textB) =>
        `※${textA}から想定される宛名シートの${textB}が「デフォルト用紙サイズ」の${textB}を超えています。<br />「デフォルト用紙サイズ」の${textB}におさまるよう値を変更してください。`
      if (this.showSheetWidthError(errors)) {
        return postCardFlg
          ? makeMessage('「左右余白」', '横幅')
          : makeMessage('「印刷の向き」「列数」「左右余白」「列間隔」', '横幅')
      }
      if (this.showSheetHeightError(errors)) {
        return postCardFlg
          ? makeMessage('「上下余白」', '縦幅')
          : makeMessage('「印刷の向き」「行数」「上下余白」「行間隔」', '縦幅')
      }
      return ''
    },
    showSheetWidthError(errors) {
      return !this.hasTextInputError(errors) && this.invalidAddressSheetWidth
    },
    showSheetHeightError(errors) {
      return (
        !this.hasTextInputError(errors) &&
        !this.invalidAddressSheetWidth &&
        this.invalidAddressSheetHeight
      )
    },
    hasTextInputError(errors) {
      const vids = [
        'topBottomMargins',
        'leftRightMargins',
        'verticalMargins',
        'horizontalMargins',
        'postCardOwnerPostalCodeVerticalPosition',
        'postCardOwnerPostalCodeHorizontalPosition',
        'postCardOwnerAddressVerticalPosition',
        'postCardOwnerAddressHorizontalPosition',
        'postCardOwnerNameVerticalPosition',
        'postCardOwnerNameHorizontalPosition',
        'postCardSenderVerticalPosition',
        'postCardSenderHorizontalPosition',
        'postCardSenderPostalCodeVerticalPosition',
        'postCardSenderPostalCodeHorizontalPosition'
      ]
      return vids.some(vid => errors[vid]?.length > 0)
    },
    setData(generalOrAddressSheet) {
      const printSettingGeneral = {}
      this.generalAttributes.forEach(
        attr => (printSettingGeneral[attr] = this.printSetting[attr])
      )
      const printSettingAddressSheet = {}
      this.addressSheetAttributes.forEach(
        attr => (printSettingAddressSheet[attr] = this.printSetting[attr])
      )
      printSettingAddressSheet.postCardSenderFlg = this.printSetting
        .postCardSenderFlg
        ? true
        : false
      if (
        generalOrAddressSheet === 'general' ||
        generalOrAddressSheet === 'all'
      ) {
        this.inputGeneral = { ...printSettingGeneral }
      }
      if (
        generalOrAddressSheet === 'addressSheet' ||
        generalOrAddressSheet === 'all'
      ) {
        this.inputAddressSheet = { ...printSettingAddressSheet }
      }
      this.mixinInputData = {
        printSettingGeneral: this.inputGeneral,
        printSettingAddressSheet: this.inputAddressSheet
      }
      this.mixinSetInitialData()
    },
    inputImage(imageData) {
      this.inputGeneral.image = imageData
    },
    deleteImage() {
      this.inputGeneral.image = null
    },
    inputStamp(stampData) {
      this.inputGeneral.stamp = stampData
    },
    deleteStamp() {
      this.inputGeneral.stamp = null
    },
    blur(inputName) {
      if (this.inputAddressSheet[inputName] === '')
        this.inputAddressSheet[inputName] = 0
    },
    async updateData(generalOrAddressSheet) {
      const inputData =
        generalOrAddressSheet === 'general'
          ? this.inputGeneral
          : this.inputAddressSheet
      if (generalOrAddressSheet !== 'general') {
        inputData.postCardSenderFlg = this.inputAddressSheet.postCardSenderFlg
          ? 1
          : 0
      }
      const sendData = {
        id: this.printSetting.id,
        clinicId: this.printSetting.clinicId,
        ...inputData
      }
      this.waitFlg = true
      const res = await this.$store.dispatch('printSetting/update', sendData)
      if (res === true) {
        this.title = '完了'
        this.buttons = ['閉じる']
        this.type = 'success'
        this.popupMessage = '編集しました'
        this.setData(generalOrAddressSheet)
      } else {
        this.title = '失敗'
        this.buttons = ['閉じる']
        this.type = 'failure'
        this.popupMessage = '編集に失敗しました'
      }
      this.waitFlg = false
      this.alertFlg = true
    },
    closePopup() {
      this.alertFlg = false
    },
    openPrintPopup(dataType, printCopy = false) {
      const { image: clinicImage, stamp: clinicStamp } = this.printSetting
      const prefectureName =
        this.getMasterDatum('prefectures', this.clinic.prefectureId)?.name || ''
      const clinic = { ...this.clinic, prefectureName }
      const basePrintOptions = {
        paperSize: this.printSetting.defaultPaperSize,
        showClinicName: this.printSetting.printClinicNameFlg === 1
      }
      if (dataType === 'medicalPayment') {
        printMedicalPayment({
          printOptions: {
            ...basePrintOptions,
            showClinicTel: Boolean(this.printSetting.printClinicTelFlg),
            showClinicEmail: Boolean(this.printSetting.printClinicEmailFlg),
            showClinicAddress: Boolean(this.printSetting.printClinicAddressFlg),
            showInvoiceNumber: Boolean(this.clinic.invoiceNumber),
            showBarcode: Boolean(this.printSetting.barcodeFlg)
          },
          clinic,
          clinicImage,
          barcodePrefix: this.printSetting.barcodePrefix,
          printData: [
            {
              owner: this.owner,
              patient: this.patient,
              medicalPayment: this.medicalPayment
            }
          ]
        })
      } else if (dataType === 'estimate') {
        printEstimate({
          printOptions: { ...basePrintOptions, printCopy },
          clinic,
          clinicImage,
          owner: this.owner,
          patient: this.patient,
          estimate: this.estimate
        })
      } else if (dataType === 'prescription') {
        printPrescription({
          printOptions: basePrintOptions,
          clinic,
          clinicImage,
          owner: this.owner,
          patient: this.patient,
          treatmentDate: today,
          prescriptionItems: this.prescriptionItems
        })
      } else if (dataType === 'examination') {
        printExamination({
          printOptions: basePrintOptions,
          clinic,
          clinicImage,
          owner: this.owner,
          patient: this.patient,
          examinationDate: today,
          examinationItems: this.examinationItems
        })
      } else if (dataType === 'receipt') {
        printReceipt({
          printOptions: {
            ...basePrintOptions,
            receiptDate: new Date(),
            ownerName: 'プレビュー飼主',
            proviso: '診療代',
            showInvoiceNumber: Boolean(this.clinic.invoiceNumber),
            printCopy
          },
          clinic,
          clinicImage,
          payment: { burdenAmount: 1000 }
        })
      } else if (dataType === 'vaccinationCertificate') {
        this.displayPatient.displayBirthday = makeBirthdayJp(
          this.displayPatient
        )
        this.displayPatient.age = calcAge(this.displayPatient)
        const ownerPrefectureName =
          this.getMasterDatum('prefectures', this.owner.prefectureId)?.name ||
          ''
        printVaccinationCertificate({
          printOptions: basePrintOptions,
          clinic,
          clinicImage,
          clinicStamp,
          owner: { ...this.owner, prefectureName: ownerPrefectureName },
          patient: this.displayPatient,
          vaccineData: {
            name: 'プレビューワクチン',
            date: today,
            nextDate: moment()
              .add(1, 'Y')
              .format('Y年M月D日'),
            staffName: 'プレビュースタッフ',
            lotNo: '1234',
            memo: ''
          }
        })
      } else if (dataType === 'addressSheet') {
        const basePrintOptions = {
          paperSize: this.printSetting.addressSheetPaperSize,
          verticalFlg: this.printSetting.addressSheetVerticalFlg,
          rows: this.printSetting.addressSheetRows,
          columns: this.printSetting.addressSheetColumns,
          horizontalMargins: this.printSetting.addressSheetHorizontalMargins,
          verticalMargins: this.printSetting.addressSheetVerticalMargins,
          leftRightMargins: this.printSetting.addressSheetLeftRightMargins,
          topBottomMargins: this.printSetting.addressSheetTopBottomMargins,
          postCardSenderFlg: this.printSetting.postCardSenderFlg,
          postCardOwnerPostalCodeVerticalPosition: this.printSetting
            .postCardOwnerPostalCodeVerticalPosition,
          postCardOwnerPostalCodeHorizontalPosition: this.printSetting
            .postCardOwnerPostalCodeHorizontalPosition,
          postCardOwnerNameVerticalPosition: this.printSetting
            .postCardOwnerNameVerticalPosition,
          postCardOwnerNameHorizontalPosition: this.printSetting
            .postCardOwnerNameHorizontalPosition,
          postCardOwnerAddressVerticalPosition: this.printSetting
            .postCardOwnerAddressVerticalPosition,
          postCardOwnerAddressHorizontalPosition: this.printSetting
            .postCardOwnerAddressHorizontalPosition,
          postCardSenderVerticalPosition: this.printSetting
            .postCardSenderVerticalPosition,
          postCardSenderHorizontalPosition: this.printSetting
            .postCardSenderHorizontalPosition,
          postCardSenderPostalCodeVerticalPosition: this.printSetting
            .postCardSenderPostalCodeVerticalPosition,
          postCardSenderPostalCodeHorizontalPosition: this.printSetting
            .postCardSenderPostalCodeHorizontalPosition
        }
        const printOptions =
          this.printSetting.addressSheetPaperSize === 'PC'
            ? {
                ...basePrintOptions,
                rows: 1,
                columns: 1,
                verticalFlg: 1,
                topBottomMargins: 5,
                leftRightMargins: 5,
                horizontalMargins: 0,
                verticalMargins: 0
              }
            : { ...basePrintOptions }
        const cellsPerPage =
          this.printSetting.addressSheetRows *
          this.printSetting.addressSheetColumns
        const baseArgs = {
          printOptions,
          cellWidth: this.cellWidth,
          cellHeight: this.cellHeight,
          owners: new Array(cellsPerPage).fill(this.owner),
          patientsMap: new Map([[this.patient.ownerId, [this.patient]]]),
          prefectures: this.prefectures
        }
        const args =
          this.printSetting.addressSheetPaperSize === 'PC'
            ? {
                ...baseArgs,
                clinic: this.clinic
              }
            : { ...baseArgs }
        printAddressSheet(args)
      }
    },
    setUploadingImg(uploadingImgState) {
      this.uploadingImg = uploadingImgState
    }
  }
}
</script>

<style lang="scss" scoped>
$input-line-height: 33px;
.print-setting {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  padding-bottom: 60px;
  text-align: left;
  font-size: 15px;
  > .general,
  .address-sheet {
    > .section {
      position: relative;
      display: flex;
      flex-direction: column;
      > .heading {
        padding-top: 22px;
        padding-bottom: 20px;
        border-bottom: 1px solid #{$light-grey};
        font-weight: bold;
      }
      > .form {
        margin-top: 30px;
        position: relative;
        display: flex;
        flex-direction: column;
        row-gap: 25px;
        > .form-item {
          display: flex;
          align-items: flex-start;
          > .label {
            width: 300px;
            min-height: $input-line-height;
            display: flex;
            align-items: center;
            > .hint {
              text-align: center;
              display: inline-block;
              font-size: 13px;
              width: 20px;
              height: 20px;
              background-color: #{$pumpkin};
              color: #{$white};
              border-radius: 50%;
              @include hover();
            }
            > .footnote-mark {
              margin-left: 6px;
              font-size: 12px;
            }
          }
          > .input-field {
            flex: 1;
            min-height: $input-line-height;
            display: flex;
            align-items: center;
            > .radio-buttons {
              display: flex;
            }
            > .image-wrap {
              width: 300px;
              height: 140px;
              display: flex;
              align-items: center;
              box-sizing: border-box;
              &.border {
                border: 2px solid #{$pumpkin};
              }
            }
            > .two-values {
              display: flex;
              width: 380px;
              > * {
                flex: 1;
              }
            }
          }
        }
        > .footnote {
          font-size: 12px;
        }
        > .layout-image {
          position: absolute;
          z-index: 1;
          left: 650px;
          top: -24px;
          height: 320px;
          background-color: #{$white};
        }
      }
      > .size-error {
        position: relative;
        > .text {
          margin-top: 2px;
          position: absolute;
          line-height: 13px;
          color: #{$tomato};
          font-size: 12px;
          padding-left: 1em;
          text-indent: -1em;
        }
      }
      > .post-card-sender-check {
        margin-top: 32px;
        display: inline-block;
      }
      > .registration {
        margin-top: 40px;
        display: flex;
        justify-content: center;
      }
      > .preview {
        margin-top: 30px;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
      }
    }
  }
}
</style>
